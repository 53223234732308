import React, { useContext, useState } from "react";
import {
  Divider,
  Icon,
  makeStyles,
  Paper,
  Typography,
  Button,
  ClickAwayListener,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import UserImage from "./UserImage";
import { AuthContext } from "../../../../../core/providers/AuthContext";
import LanguageSelector from "../dropdown/LanguageSelector";
import ReactCountryFlag from "react-country-flag";
import { flagIConStart } from "../../../../../solutions/employee-store/layout/header/components/EmployeeStoreLogo";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: 320,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: 15,
    boxShadow: "rgb(0 0 0 / 20%) 5px 2px 10px",
    [theme.breakpoints.down("xs")]: {
      width: 250,
      height: 175,
      padding: 10,
    },
  },
  paperContainerEms: {
    width: 320,
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: 15,
    boxShadow: "rgb(0 0 0 / 20%) 5px 2px 10px",
    [theme.breakpoints.down("xs")]: {
      width: 250,
      height: "auto",
      padding: 10,
    },
  },
  profileInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    overflow: "auto",
    marginBottom: 6,
  },
  profileInfoTexts: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: 10,
  },
  buttonLogoutRoot: {
    padding: 10,
    height: 30,
    marginTop: 12,
    backgroundColor: `${theme.palette.content.mainColor}`,
    "&:hover": {
      backgroundColor: `${theme.palette.content.mainColor}`,
      transform: "scale(1.05)",
    },
  },
  buttonLogoutLabel: {
    textTransform: "none",
    gap: 5,
  },
  logoutTypo: {
    color: "white",
  },
  profileUserNameTypo: {
    fontSize: "0.75rem",
    fontWeight: "700",
    lineHeight: "1.6",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.65rem",
    },
  },
  toggleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "none",
    width: "100%",
  },
  iconAndLabelBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
  },
}));

const ENABLE_LANG_SELCTOR_EMS = false;

export default function ProfilePopoverContent({
  setOpenLogoutConfirmation,
  setOpenProfilePop,
  isEms,
}) {
  const classes = useStyles();
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  const toggleLanguageSelector = () => {
    setShowLanguageSelector(!showLanguageSelector);
  };

  const EndToogleIcon = () => {
    if (showLanguageSelector) {
      return <Icon className="fas fa-times" />;
    } else {
      return null;
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenProfilePop(false)}>
      <Paper
        className={isEms ? classes.paperContainerEms : classes.paperContainer}
      >
        <div className={classes.profileInfo}>
          <UserImage isProfile={true} />
          <div className={classes.profileInfoTexts}>
            <Typography className={classes.profileUserNameTypo}>
              {auth?.userName}{" "}
              {isEms ? (
                <ReactCountryFlag
                  svg
                  countryCode={auth?.emsCountry}
                  style={flagIConStart}
                />
              ) : null}
            </Typography>
            <Typography variant="subtitle1">{auth?.company?.name}</Typography>
            {isEms && (
              <Typography variant="subtitle2">
                {t("EMPLOYEE_STORE.COD_EMPLOYEE", {
                  code: auth?.emsClient,
                })}
              </Typography>
            )}
          </div>
        </div>
        <Divider />
        {isEms && ENABLE_LANG_SELCTOR_EMS && (
          <>
            <Box mt={1} mb={1}>
              <Button
                onClick={toggleLanguageSelector}
                className={classes.toggleButton}
              >
                <Box className={classes.iconAndLabelBox}>
                  <Icon className="fas fa-flag" />
                  {t("DD_SETTINGS_LANGS")}
                </Box>

                <EndToogleIcon />
              </Button>
              {showLanguageSelector && (
                <Box mt={1}>
                  <LanguageSelector isEms={true} />
                </Box>
              )}
            </Box>
            <Divider />
          </>
        )}
        <Button
          classes={{
            root: classes.buttonLogoutRoot,
            label: classes.buttonLogoutLabel,
          }}
          onClick={() => {
            setOpenLogoutConfirmation(true);
          }}
        >
          <Icon className="fas fa-sign-out-alt" style={{ color: "white" }} />
          <Typography className={classes.logoutTypo}>
            {t("TOOLTIP_LOGOUT")}
          </Typography>
        </Button>
      </Paper>
    </ClickAwayListener>
  );
}
