import React, { useContext, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Paper,
  Box,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Icon,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import { AuthContext } from "../../../../core/providers/AuthContext";
import { isEmpty, isNil, size, sumBy } from "lodash";
import useCart from "../../store/hooks/useCart";
import {
  EMS_MIN_QUANTITY_FIELD,
  EMS_PRICE_FIELD,
  formatPriceWithCountry,
  PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS,
} from "../../util/util-ems";
import useSimulation from "../../store/hooks/useSimulation";
import ShoppingCartTestSplitBtn from "./tests/ShoppingCartTestSplitBtn";
import ShoppingCartTestCreatePayment from "./tests/ShoppingCartTestCreatePayment";
import useCheckout from "../../store/hooks/useCheckout";
import { EMS_REDIRECT_PAYMENT_URL } from "../../routes/EmployeeStoreRouter";
import EmployeeStorePolicy from "../../layout/footer/components/EmployeeStorePolicy";
import { toast } from "react-toastify";
import { TOAST_CONTAINER_LAYOUT } from "../../../../util/Constants";
import useInfo from "../../store/hooks/useInfo";
import EmployeeStorePreCheckoutInfoDialog from "./dialogs/EmployeeStorePreCheckoutInfoDialog";

const useStyles = makeStyles((theme) => ({
  orderSummary: {
    padding: theme.spacing(2),
    border: `4px solid ${theme.palette.content.mainColor}`,
    [theme.breakpoints.down(1280)]: {
      width: "100%",
    },
  },
  continueButton: {
    minHeight: 40,
    cursor: "pointer",
    backgroundColor: theme.palette.content.mainColor,
    "&:hover": {
      backgroundColor: theme.palette.content.mainColor,
      transform: "scale(1.02)",
    },
  },
  stepper: {
    padding: 10,
  },
  stepperActionsContainer: {
    display: "flex",
    gap: 12,
    alignItems: "center",
    justifyContent: "space-between",
  },
  loading: {
    color: "white",
  },
  successIcon: {
    color: theme.palette.semaphore.green,
  },
  typoGood: {
    color: theme.palette.semaphore.green,
  },
  typoBad: {
    color: theme.palette.semaphore.orange,
  },
  typoTestsTitle: {
    position: "absolute",
    top: "-12px",
    right: "0",
    marginRight: "50%",
    backgroundColor: "darkorange",
    padding: "3px",
    borderRadius: "7px",
    color: "white",
    fontWeight: "bold",
  },
  typoButtonUnderline: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function getSteps() {
  return ["Estimado inicial", "Simulación orden", "Resultado operación"];
}

const ShoppingCartOrderSummary = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = getSteps();

  const headers = useRequestHeaders();
  const { auth, logout } = useContext(AuthContext);
  const { initialInfo, handleFetchPreCheckoutInfo } = useInfo();

  const [politicsAccepted, setPoliticsAccepted] = useState(false);
  const [showErrorPolitics, setShowErrorPolitics] = useState(false);

  const [internalLoading, setInternalLoading] = useState(false);

  const { emsCartId, cartItems } = useCart();
  const sizeCartItems = size(cartItems) || 0;

  const estimatedTotal = useMemo(() => {
    return formatPriceWithCountry({
      price: sumBy(cartItems, function (o) {
        return o?.[EMS_PRICE_FIELD] * o?.[EMS_MIN_QUANTITY_FIELD];
      }),
      country: auth?.emsCountry,
    });
  }, [auth, cartItems]);

  const {
    parentSimulationId,
    simulationTotalizers,
    isSimulating,
    handleSimulation,
    simulationsIds,
  } = useSimulation();

  const {
    checkoutStep,
    handleBackCheckoutStep,
    createPayment,
    validatingPayment,
    resetCheckoutStep,
  } = useCheckout();

  const handleClickSimulateBtn = async () => {
    await handleSimulation({
      headers,
      logout,
      t,
      emsIdCompra: emsCartId
    });
  };

  const handleChangePoliticsAccepted = (event) => {
    const newState = event.target.checked;
    setPoliticsAccepted(newState);
    if (newState === true) {
      setShowErrorPolitics(false);
    }
  };

  const hadleClickProcessPayment = async () => {
    try {
      if (!politicsAccepted) {
        setShowErrorPolitics(true);
        return;
      }
      setInternalLoading(true);

      const responsePreCheckoutInfo = await handleFetchPreCheckoutInfo();
      if (
        !isNil(responsePreCheckoutInfo) &&
        responsePreCheckoutInfo?.showPreCheckoutConfirmationDialog === true
      ) {
        return;
      }

      // If all ok, then crate the payment
      handleCreatePayment();
    } catch (_) {
    } finally {
      setInternalLoading(false);
    }
  };

  const handleCreatePayment = async () => {
    const response = await createPayment({
      paymentSystem: PAYMENT_SYSTEM_TRANSBANK_WEBPAY_PLUS,
      body: {
        simulationIds: simulationsIds,
        returnUrl: EMS_REDIRECT_PAYMENT_URL,
        totalAmount: simulationTotalizers.totalNF,
        emsIdCompra: emsCartId,
        parentSimulationId: parentSimulationId,
      },
    });

    if (!isNil(response) && response?.ok === false) {
      toast.error(response?.error || t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
        containerId: TOAST_CONTAINER_LAYOUT,
        toastId: "hadleClickProcessPayment",
        autoClose: false,
      });
      return;
    }

    if (response?.data?.url && response?.data?.token_ws) {
      window.location.href = `${response.data.url}?token_ws=${response.data.token_ws}`;
    }
  };

  return (
    <Paper className={classes.orderSummary}>
      <EmployeeStorePreCheckoutInfoDialog
        handleCreatePayment={handleCreatePayment}
      />
      <Typography variant="h4">
        {t("EMPLOYEE_STORE.SUMMARY_PURCHASE")}: #{emsCartId}
      </Typography>
      <Stepper
        activeStep={checkoutStep}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {checkoutStep === 0 && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6">
            {" "}
            {t("EMPLOYEE_STORE.PRODUCT_QUANTITY")}:
          </Typography>
          <Typography variant="h6" color="secondary">
            {sizeCartItems} productos
          </Typography>
        </Box>
      )}
      {checkoutStep === 2 ? (
        <>
          <Button
            onClick={resetCheckoutStep}
            color="default"
            variant="contained"
            startIcon={<Icon className="fas fa-chevron-left" />}
            fullWidth
          >
            {t("FORM_BACK")}
          </Button>
        </>
      ) : checkoutStep === 1 && !isNil(simulationTotalizers) ? (
        <>
          <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.TOTAL_UNITS")}:
            </Typography>
            <Typography>{simulationTotalizers.totalItems}</Typography>
          </Box>
          <Divider />

          <Box display="flex" justifyContent="space-between" mt={2} mb={1}>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.SIMULATION_TOTAL_PRODUCTS_PRICE")}:
            </Typography>
            <Typography variant="h6" color="secondary">
              {formatPriceWithCountry({
                price: simulationTotalizers.totalPriceProducts,
                country: auth?.emsCountry,
              })}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
            mb={2}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Typography variant="h6">
                {t("EMPLOYEE_STORE.SIMULATION_ITEM_TOTAL_DESC")}:
              </Typography>
            </Box>

            <Typography className={classes.typoGood}>
              {formatPriceWithCountry({
                price: simulationTotalizers.totalDiscounts,
                country: auth?.emsCountry,
              })}
            </Typography>
          </Box>

          <Divider />
          <Box display="flex" justifyContent="space-between" mb={1} mt={2}>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.SIMULATION_ITEM_CA")}:
            </Typography>
            <Typography variant="h6" color="secondary">
              {formatPriceWithCountry({
                price: simulationTotalizers.totalCA,
                country: auth?.emsCountry,
              })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.SIMULATION_ITEM_TAXES")}:
            </Typography>
            <Typography variant="h6" className={classes.typoBad}>
              {formatPriceWithCountry({
                price: simulationTotalizers.totalTaxes,
                country: auth?.emsCountry,
              })}{" "}
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <Typography variant="h4">
              {t("EMPLOYEE_STORE.TOTAL_PURCHASE")}:
            </Typography>
            <Typography variant="h4" color="primary">
              {formatPriceWithCountry({
                price: simulationTotalizers.totalNF,
                country: auth?.emsCountry,
              })}
            </Typography>
          </Box>
          <Divider />
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6">
              {t("EMPLOYEE_STORE.TOTAL_ESTIMATED")}:
            </Typography>
            <Typography variant="h6" color="secondary">
              {estimatedTotal}
            </Typography>
          </Box>
        </>
      )}

      {checkoutStep === 1 && (
        <>
          <Box mb={2} mt={2} display="flex">
            <Checkbox
              checked={politicsAccepted}
              onChange={handleChangePoliticsAccepted}
              style={{ color: showErrorPolitics && "red" }}
            />
            <EmployeeStorePolicy footer={false} />
          </Box>
        </>
      )}

      {checkoutStep === 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            className={classes.continueButton}
            onClick={handleClickSimulateBtn}
            disabled={
              sizeCartItems === 0 ||
              isNil(cartItems) ||
              isEmpty(cartItems) ||
              initialInfo?.isEmsClosed === true ||
              isSimulating
            }
          >
            {isSimulating ? (
              <CircularProgress className={classes.loading} size={16} />
            ) : (
              "Simular orden"
            )}
          </Button>
        </>
      ) : checkoutStep === 1 ? (
        <>
          <div className={classes.stepperActionsContainer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              className={classes.continueButton}
              onClick={hadleClickProcessPayment}
              disabled={
                initialInfo?.isEmsClosed === true ||
                validatingPayment ||
                internalLoading
              }
            >
              {validatingPayment || internalLoading ? (
                <CircularProgress className={classes.loading} size={16} />
              ) : (
                "Confirmar y Proceder al pago"
              )}
            </Button>
            <Button
              onClick={handleBackCheckoutStep}
              color="default"
              variant="contained"
              startIcon={<Icon className="fas fa-chevron-left" />}
            >
              {t("FORM_BACK")}
            </Button>
          </div>
          {showErrorPolitics ? (
            <Box mt={1}>
              <Alert severity="error">
                {t("EMPLOYEE_STORE.MUST_ACCEPT_POLICIES")}
              </Alert>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {/**
           * <Button
            variant="outlined"
            color="primary"
            startIcon={<Icon className="fas fa-download" />}
            fullWidth={true}
          >
            Descarga comprobante
          </Button>
           */}
        </>
      )}

      {process.env.NODE_ENV === "development" &&
        initialInfo?.isEmsClosed !== true &&
        checkoutStep < 2 && (
          <Box
            width={"100%"}
            mt={2}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            border={"1px solid"}
            padding={"24px 8px"}
            borderRadius={10}
            fontWeight={"bold"}
            position={"relative"}
          >
            <Typography className={classes.typoTestsTitle}>TESTS </Typography>
            {checkoutStep === 0 ? <ShoppingCartTestSplitBtn /> : null}
            {checkoutStep === 1 ? <ShoppingCartTestCreatePayment /> : null}
          </Box>
        )}
    </Paper>
  );
};

export default ShoppingCartOrderSummary;
