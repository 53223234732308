import React, { useContext, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  makeStyles,
  Button,
} from "@material-ui/core";
import domtoimage from "dom-to-image";
import { toast } from "react-toastify";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Alert } from "@material-ui/lab";
import {
  EMS_PAYMENT_STATUS_AUTHORIZED,
  EMS_PAYMENT_STATUS_INITIALIZED,
  EMS_TBK_PAYMENT_TYPES,
  EMS_TBK_PAYMENTS_RESPONSE_CODES,
  formatPriceWithCountry,
} from "../../util/util-ems";
import { useTranslation } from "react-i18next";
import { get, isNil } from "lodash";
import { safeFormattingDatePaymentISO } from "../../../../util/UtilDates";
import { AuthContext } from "../../../../core/providers/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 20,
    width: "30%",
    //height: "85%",
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },

  mainEmptyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 20,
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  boxToExport: {
    gap: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxHeight: "max-content",
    backgroundColor: "#FFF",
    padding: 8,
    marginTop: 10,
  },
  resultAlert: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },

  tableContainer: {
    height: "100%",
  },
  table: {
    height: "100%",
  },
  tableRow: {
    padding: theme.spacing(2),
  },
  stripedRow: {
    backgroundColor: `${theme.palette.content.mainColor}15`,
  },
  downloadBtn: {
    width: "100%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    textTransform: "none",
    marginTop: 24,
  },
}));

const ROWS_DEFINITION = [
  {
    key: "buyOrder",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_ID_COMPRA",
  },
  {
    key: "storeName",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_STORE_NAME",
    fixed: true,
    fixedValue: "EMPLOYEE_STORE.DISPLAY_NAME",
  },
  {
    key: "transactionCurrency",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_TRANSACTION_CURRENCY",
    fixed: true,
    fixedValue: "CLP", // TODO: Resolve later
  },
  {
    key: "amount",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_TRANSACTION_AMOUNT",
  },
  {
    key: "responseCode",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_AUTHORIZATION_CODE",
  },
  {
    key: "transactionDate",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_TRANSACTION_DATE",
  },
  {
    key: "paymentTypeCode",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_PAYMENT_TYPE",
  },
  {
    key: "installmentsNumber",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_INSTALLMENT_COUNT",
  },
  {
    key: "installmentsAmount",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_INSTALLMENT_AMOUNT",
  },
  {
    key: "cardDetail.cardNumber",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_CARD_LAST_FOUR_DIGITS",
  },
  {
    key: "itemsDescription",
    label: "EMPLOYEE_STORE.CHECKOUT_TABLE_COL_CARD_ITEMS_DESCRIPTION",
  },
];
const formatFieldInResponse = ({ key, paymentSystemResponse, emsCountry }) => {
  switch (key) {
    case "transactionDate":
      const formattedDate = safeFormattingDatePaymentISO({
        value: get(paymentSystemResponse, key),
        convertFromUtc: false,
      });
      return `${formattedDate}`;
    case "paymentTypeCode":
      return get(EMS_TBK_PAYMENT_TYPES, get(paymentSystemResponse, key));
    case "responseCode":
      return get(
        EMS_TBK_PAYMENTS_RESPONSE_CODES,
        get(paymentSystemResponse, key)
      );
    case "amount":
      return formatPriceWithCountry({
        price: get(paymentSystemResponse, key),
        country: emsCountry,
      });
    default:
      return get(paymentSystemResponse, key);
  }
};

export default function PaymentCheckoutResult({
  paymentStatus,
  paymentSystemResponse,
  itemsDescription,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const infoRef = useRef(null);
  const { auth } = useContext(AuthContext);

  const emsCountry = auth?.emsCountry;

  const handleDownloadImage = () => {
    const node = infoRef.current;

    domtoimage
      .toPng(node, {
        style: {
          marginInline: "16px",
        },
      })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${paymentSystemResponse?.buyOrder}.png`;
        link.click();
      })
      .catch((error) => {
        toast.error("Error descargando comprobante: " + error);
      });
  };

  if (isNil(paymentSystemResponse)) {
    return (
      <Box className={classes.mainEmptyBox}>
        <Alert
          severity="error"
          variant="outlined"
          className={classes.resultAlert}
        >
          {t("EMPLOYEE_STORE.CHECKOUT_STATUS_ERROR")}
        </Alert>
      </Box>
    );
  }

  return (
    <Box className={classes.mainBox}>
      {infoRef != null && (
        <Button
          onClick={handleDownloadImage}
          className={classes.downloadBtn}
          variant="outlined"
          startIcon={<GetAppIcon />}
        >
          {t("EMPLOYEE_STORE.CHECKOUT_DOWNLOAD_INFO")}
        </Button>
      )}

      <Box className={classes.boxToExport} ref={infoRef}>
        {paymentStatus === EMS_PAYMENT_STATUS_AUTHORIZED ||
        paymentStatus === EMS_PAYMENT_STATUS_INITIALIZED ? (
          <Alert
            severity="success"
            variant="outlined"
            className={classes.resultAlert}
          >
            {t("EMPLOYEE_STORE.CHECKOUT_STATUS_OK", {
              emsIdCompra: paymentSystemResponse?.buyOrder,
            })}
          </Alert>
        ) : (
          <Alert
            severity="error"
            variant="outlined"
            className={classes.resultAlert}
          >
            {t("EMPLOYEE_STORE.CHECKOUT_STATUS_ERROR", {
              emsIdCompra: paymentSystemResponse?.buyOrder,
            })}
          </Alert>
        )}
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableBody>
              {ROWS_DEFINITION.map((row, index) => {
                const { key, label, fixed, fixedValue } = row;
                const valueToShow =
                  fixed === true
                    ? t(fixedValue)
                    : key === "itemsDescription"
                    ? null
                    : formatFieldInResponse({
                        key,
                        paymentSystemResponse,
                        emsCountry,
                      });
                return (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${
                      index % 2 === 0 ? classes.stripedRow : ""
                    }`}
                  >
                    <TableCell>
                      <strong>{t(label)}</strong>
                    </TableCell>
                    {key === "itemsDescription" ? (
                      <TableCell>
                        {Object.entries(itemsDescription).map(
                          ([key, value]) => (
                            <p>{`x${value} ${key}`}</p>
                          )
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>{valueToShow}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
